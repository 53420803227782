<template>
    <div class="form-check flex justify-between form-switch">
        <label class="form-check-label inline-block text-gray-800" :for="fieldData.field_id" v-if="fieldData.label" v-text="fieldData.label" />
        <input class="form-check-input mr-4 appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm" type="checkbox" @change="handleChange" role="switch" :id="fieldData.field_id" />
    </div>
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getValue: {
            get() {
                return this.fieldData.value || false;
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            },
        },
    },
    methods: {
        handleChange({target}) {
            this.getValue = target.checked
        }
    }
};
</script>

<style lang="scss" scoped>
.form-switch {
    // padding-left: 2.5em;
    .form-check-input {
        margin-top: 0.1rem;
        background-color: #eee;
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
        background-position: 0;
        transition: background-position 0.15s ease-in-out;
    }
    .form-check-input:checked {
        background-position: 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E");
    }
    .form-check-input:checked[type="checkbox"] {
        background-color: var(--brand-color);
    }
}
</style>